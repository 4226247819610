.button {
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  transition-property: background-color, border, box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  color: #333333;
  border: 1px solid #ffdd2d;
  background-color: #ffdd2d;
  border-radius: 4px;
  padding: 12px 20px;
}
.button:disabled, button:disabled:hover {
  background-color: #fcd663;
  cursor: not-allowed;
}

.button:hover {
  border-color: #fcc521;
  background-color: #fcc521;
}
